<script>
export default {
  name: 'ContentInfo',
}
</script>

<template>
  <v-alert
      text
      outlined
      color="deep-orange"
      icon="mdi-fire"
    >
    <div><slot/></div>

  </v-alert>

</template>

<style scoped>

</style>